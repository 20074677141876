<template>
    <!-- give the sidebar z-50 class so its higher than the navbar if you want to see the logo -->
    <!-- you will need to add a little "X" button next to the logo in order to close it though -->
    <div class="w-1/2 overflow-y-auto md:w-1/3 lg:w-64 fixed md:top-0 md:left-0 h-screen lg:block bg-gray-100 border-r z-30" id="main-nav" :class="sideBarOpen ? '' : 'hidden'">

        <div class="w-full sticky top-0 bg-gray-100 h-20 border-b flex px-4 items-center mb-5">
            <div class="font-semibold text-3xl text-theme-500">
                <div class="w-56 text-right text-xs text-slate-500 font-light tracking-wide">{{ $page.props.app.instance }}</div>
                <Link :href="route('welcome')">
                    <ApplicationLogo class="w-56 h-auto fill-theme-500" />
                </Link>
            </div>
        </div>

        <div class="mb-4 px-4">
            <Link :href="route('dashboard')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('dashboard') ? 'bg-gray-200' : ''">
            <HomeIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('Dashboard') }}</span>
            </Link>
        </div>

        <div class="mb-4 px-4">
            <p class="pl-4 text-sm font-semibold mb-1 uppercase">{{ __('Patients') }}</p>
            <Link :href="route('patient.index')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('patient.index') ? 'bg-gray-200' : ''">
            <ListBulletIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('My Patients') }}</span>
            </Link>
            <Link :href="route('cohort.index')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('cohort.index') ? 'bg-gray-200' : ''">
            <UserGroupIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('My Cohorts') }}</span>
            </Link>
            <Link :href="route('patient.create')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('patient.create') ? 'bg-gray-200' : ''">
            <PlusCircleIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('New Patient') }}</span>
            </Link>
            <Link :href="route('search.advanced')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('search.advanced') ? 'bg-gray-200' : ''">
            <PlusCircleIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('New Cohort') }}</span>
            </Link>
            <Link :href="route('patient.import')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('patient.import') ? 'bg-gray-200' : ''">
            <ArrowUpOnSquareIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('Import Patients') }}</span>
            </Link>
        </div>

        <div class="mb-4 px-4">
            <p class="pl-4 text-sm font-semibold mb-1 uppercase">{{ __('Samples') }}</p>
            <Link :href="route('process_vcf.index')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('process_vcf.index') ? 'bg-gray-200' : ''">
            <ListBulletIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('My Jobs') }}</span>
            </Link>
            <Link :href="route('samples.batch')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('samples.batch') ? 'bg-gray-200' : ''">
            <ClipboardDocumentListIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('Samples Batch Import') }}</span>
            </Link>
        </div>

        <div class="mb-4 px-4">
            <p class="pl-4 text-sm font-semibold mb-1 uppercase">{{ __('Analyses') }}</p>
            <Link :href="route('analysis.index')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('analysis.index') ? 'bg-gray-200' : ''">
            <ListBulletIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('My Analyses') }}</span>
            </Link>
            <Link :href="route('analysis.new')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('analysis.new') ? 'bg-gray-200' : ''">
            <PlusCircleIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('New Analysis') }}</span>
            </Link>
        </div>

        <div class="mb-4 px-4">
            <p class="pl-4 text-sm font-semibold mb-1 uppercase">{{ __('Working Groups') }}</p>
            <Link :href="route('group.index')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('group.index') ? 'bg-gray-200' : ''">
            <ListBulletIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('My Groups') }}</span>
            </Link>
            <Link :href="route('group.create')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('group.create') ? 'bg-gray-200' : ''">
            <PlusCircleIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('New Group') }}</span>
            </Link>
        </div>

        <div class="mb-4 px-4">
            <p class="pl-4 text-sm font-semibold mb-1 uppercase">{{ __('Panels') }}</p>
            <Link :href="route('panel.index')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('panel.index') ? 'bg-gray-200' : ''">
            <ListBulletIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('My Panels') }}</span>
            </Link>
            <!-- <Link :href="route('panel.create')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('panel.create') ? 'bg-gray-200' : ''">
            <PlusCircleIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('New Panel') }}</span>
            </Link> -->
        </div>

        <div class="mb-4 px-4">
            <p class="pl-4 text-sm font-semibold mb-1 uppercase">{{ __('Filters') }}</p>
            <Link :href="route('filters.index')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('filters.index') ? 'bg-gray-200' : ''">
            <FunnelIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('My Filters') }}</span>
            </Link>
            <!-- <Link :href="route('panel.create')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer"
                :class="route().current('panel.create') ? 'bg-gray-200' : ''">
            <PlusCircleIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('New Panel') }}</span>
            </Link> -->
        </div>

        <div class="mb-4 px-4">
            <p class="pl-4 text-sm font-semibold mb-1 uppercase">{{ __('System') }}</p>
            <Link href="" @click="callDoc('global')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer">
            <BookOpenIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('General Documentation') }}</span>
            </Link>
            <Link href="" @click="callDoc('version')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer">
            <Square3Stack3DIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('Version Documentation') }}</span>
            </Link>
            <Link href="" @click="callDoc('api')" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer">
            <CodeBracketSquareIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('API Documentation') }}</span>
            </Link>
            <Link href="" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer">
            <EnvelopeIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('Contact Us') }}</span>
            </Link>
            <Link href="" class="w-full flex items-center text-theme-500 h-10 pl-4 hover:bg-gray-200 rounded-lg cursor-pointer">
            <InformationCircleIcon class="h-6 w-6 mr-2" />
            <span class="text-gray-700">{{ __('About') }}</span>
            </Link>
        </div>
    </div>
</template>

<script setup>
import { inject } from "vue";
import { Link } from '@inertiajs/vue3';
import { HomeIcon, PlusCircleIcon, ListBulletIcon, ArrowUpOnSquareIcon, EnvelopeIcon, BookOpenIcon, InformationCircleIcon, ClipboardDocumentListIcon, UserGroupIcon, CodeBracketSquareIcon, Square3Stack3DIcon, FunnelIcon } from '@heroicons/vue/24/outline';
import ApplicationLogo from "@/Components/ApplicationLogo.vue";

const sideBarOpen = inject("sideBarOpen");

const callDoc = (type) => {
    if (type == 'global') {
        window.open("/docs/global", "_blank");
    } else if (type == 'version') {
        window.open("/docs", "_blank");
    } else if (type == 'api') {
        window.open("/api_doc", "_blank");
    }
};
</script>
